import { MAILTO_EMAIL } from "../../constants";
import fieldsArr from "./atNeedFields";
import schema from "./atNeedSchema";
import "./form.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { navigate } from "@reach/router";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

const makeTitleCase = (str) =>
  str
    .split(" ")
    .split("-")
    .map(
      ([firstChar, ...rest]) =>
        firstChar.toUpperCase() + rest.join("").toLowerCase()
    )
    .join(" ");

const AtNeedForm = () => {
  const [loading, setLoading] = useState(false);
  // const disabled = loading;
  const defaultValues = {
    sex: undefined,
    "birth-state": undefined,
    "death-location": undefined,
    "marital-status": undefined,
    "residence-state": undefined,
    "city-limits": undefined,
    "armed-forces": undefined,
    "armed-forces-discharge-papers": undefined,
    education: undefined,
    race: undefined,
    "informant-state": undefined,
    hospice: undefined,
    pacemaker: undefined,
    "death-certificates-number": 0,
    "obit-consent": true,
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const deathCertificatesNumber = watch("death-certificates-number", 0);

  const formURL =
    "https://3dodu20k1m.execute-api.us-east-1.amazonaws.com/Prod/send";
  const onSubmit = (submission) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 20000);
    const now = dayjs();
    const dateFormat = (date) => dayjs(date).format("MM/DD/YYYY");
    const ssnFormat = (ssn) => {
      const ssnArr = Array.from(ssn.split("-").join(""));
      ssnArr.splice(5, 0, "-");
      ssnArr.splice(3, 0, "-");
      return ssnArr.join("");
    };
    submission["date-of-birth"] = dateFormat(submission["date-of-birth"]);
    submission["date-of-death"] = dateFormat(submission["date-of-death"]);
    console.log(submission);
    submission.ssn = ssnFormat(submission.ssn);
    console.log(submission);
    submission["death-certificates-number"] =
      3 + +submission["death-certificates-number"];

    const dehyphenate = (text) => {
      // if (text.indexOf('-') !== -1) {
      return text
        .replace(/-/g, " ")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      // } else return text;
    };

    const stringFormatter = (fieldsArr, responsesArr) => {
      let retval = "";
      for (var i = 0; i < fieldsArr.length; i++) {
        retval += dehyphenate(fieldsArr[i]) + ": " + responsesArr[i] + "\n\n";
      }
      return retval;
    };

    const toEmails = [MAILTO_EMAIL];
    // const toEmails = ['info@cityofoakscremation.com'];
    // const toEmails = ['mitchell@lyda.me'];
    const ccEmails = ["mailer@lyda.me"];

    const data = Object.assign(
      {
        "submission-type": "At-Need VSI",
        "time-submitted": now.format("MM/DD/YYYY HH:mm"),
      },
      submission
    );
    const responsesArr = [];
    fieldsArr.map((field) => responsesArr.push(data[field]));

    const subject = `VSI for ${submission["legal-name-first"]} ${submission["legal-name-last"]}`;
    const message = stringFormatter(fieldsArr, responsesArr);
    const {
      "date-of-death": dateOfDeath,
      "legal-name-first": firstName,
      "legal-name-middle": middleName,
      "legal-name-last": lastName,
      "residence-city": city,
      "residence-county": county,
      "residence-state": hyphenState,
      "obit-consent": obitConsent,
      "informant-email": informantEmail,
      age,
    } = submission;
    const slug = `${dayjs(dateOfDeath).format(
      "YYYY-MM-DD"
    )}-${firstName.trim()}-${lastName.trim()}`;
    const name = `${firstName} ${
      +middleName ? middleName + " " : ""
    }${lastName}`;
    const deathdate = dayjs(dateOfDeath).toISOString();
    const state = dehyphenate(hyphenState);
    const directLink = `https://www.cityofoakscremation.com/obituaries/${slug}`;
    const obitInfo = {
      slug,
      name: name.trim(),
      city: city.trim(),
      county: county.trim(),
      state,
      deathdate,
      age,
      informantEmail: informantEmail.trim(),
      directLink,
      published_at: null,
    };

    const mailObj = {
      toEmails,
      ccEmails,
      subject,
      message,
      obitInfo: obitConsent ? obitInfo : null,
      informantEmail,
    };

    // Create the AJAX request
    const xhr = new XMLHttpRequest();
    xhr.open("POST", formURL, true);
    xhr.setRequestHeader("Accept", "application/json; charset=utf-8");
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

    // Send the collected data as stringified JSON
    console.log(mailObj);
    xhr.send(JSON.stringify(mailObj));

    xhr.onloadend = (response) => {
      setLoading(false);
      if (response.target.status === 200 || response.target.status === 204) {
        navigate("/success/", {
          state: {
            message: `Thank you for providing us with the requested information. We will be back in touch soon with the next steps.`,
          },
        });
        console.log("200 or 204", response, mailObj);
      } else {
        alert(
          "Oops! We had a hiccup with our server. Try to submit one more time and if you get this error again, please check with us because we may have already received your information."
        );
        console.log("not 200 or 204", response, mailObj);
        // window.location = 'https://www.cityofoakscremation.com/success/';
      }
      console.log(errors);
    };
  };

  return (
    <>
      <form className="form-input" onSubmit={handleSubmit(onSubmit)}>
        gg
        <div className="rendered-form">
          <div className=" form-group field-legal-name">
            <br />
            <h3>Decedent's full legal name:</h3>
            <div className="block">
              <label for="legal-name-first" className="text-gray-700">
                First:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("legal-name-first")}
                access="false"
                id="legal-name-first"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["legal-name-first"] && (
              <div className="error-msg">
                {errors["legal-name-first"].message}
              </div>
            )}
            <div className="block">
              <label for="legal-name-middle" className="text-gray-700">
                Middle:
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("legal-name-middle")}
                access="false"
                id="legal-name-middle"
              />
            </div>
            {errors["legal-name-middle"] && (
              <div className="error-msg">
                {errors["legal-name-middle"].message}
              </div>
            )}
            <div className="block">
              <label for="legal-name-last" className="text-gray-700">
                Last:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("legal-name-last")}
                access="false"
                id="legal-name-last"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["legal-name-last"] && (
              <div className="error-msg">
                {errors["legal-name-last"].message}
              </div>
            )}
            <div className=" form-group field-maiden-name"></div>
            <div className="block">
              <label for="maiden-name" className="text-gray-700">
                Last name prior to first marriage:
                <span className="tooltip">
                  {" "}
                  <span className="tooltip-text">(if applicable)</span>?
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("maiden-name")}
                access="false"
                id="maiden-name"
                title="(if applicable)"
              />
            </div>
            {errors["maiden-name"] && (
              <div className="error-msg">{errors["maiden-name"].message}</div>
            )}
            <div className=" form-group field-sex"></div>
            <div className="block">
              <label for="sex" className="text-gray-700">
                Sex:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
                <span className="tooltip">
                  <span className="tooltip-text">
                    (according to official records)
                  </span>
                </span>
                <br />
              </label>
              <select
                className="form-select sex"
                {...register("sex")}
                access="false"
                id="sex"
                required="required"
                aria-required="true"
              >
                <option value={undefined} disabled selected="true">
                  Select sex:
                </option>
                <option value="male" id="sex-0">
                  Male
                </option>
                <option value="female" id="sex-1">
                  Female
                </option>
              </select>
            </div>
            {errors.sex && (
              <div className="error-msg">{errors.sex.message}</div>
            )}
            <div className=" form-group field-age"></div>
            <div className="block">
              <label for="age" className="text-gray-700">
                Age at last birthday:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("age")}
                access="false"
                maxlength="3"
                id="age"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["age"] && (
              <div className="error-msg">{errors["age"].message}</div>
            )}
            <br />
            <h3>County and state of birth:</h3>
            <div className=" form-group field-county"></div>
            <div className="block">
              <label for="county" className="text-gray-700">
                County:
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input county"
                {...register("county")}
                access="false"
                // value=""
                id="county"
              />
            </div>
            {errors["county"] && (
              <div className="error-msg">{errors["county"].message}</div>
            )}
            <div className=" form-group field-birth-state"></div>
            <div className="block">
              <label for="birth-state" className="text-gray-700">
                State: <br />{" "}
              </label>
              <select
                className="form-select decedent-form"
                {...register("birth-state")}
                access="false"
                id="birth-state"
              >
                {" "}
                <option value={undefined} disabled selected="true">
                  Please select:
                </option>
                <option value="Alabama" id="birth-state-0">
                  Alabama
                </option>
                <option value="Alaska" id="birth-state-1">
                  Alaska
                </option>
                <option value="Arizona" id="birth-state-2">
                  Arizona
                </option>
                <option value="Arkansas" id="birth-state-3">
                  Arkansas
                </option>
                <option value="California" id="birth-state-4">
                  California
                </option>
                <option value="Colorado" id="birth-state-5">
                  Colorado
                </option>
                <option value="Connecticut" id="birth-state-6">
                  Connecticut
                </option>
                <option value="Delaware" id="birth-state-7">
                  Delaware
                </option>
                <option value="Florida" id="birth-state-8">
                  Florida
                </option>
                <option value="Georgia" id="birth-state-9">
                  Georgia
                </option>
                <option value="Hawaii" id="birth-state-10">
                  Hawaii
                </option>
                <option value="Idaho" id="birth-state-11">
                  Idaho
                </option>
                <option value="Illinois" id="birth-state-12">
                  Illinois
                </option>
                <option value="Indiana" id="birth-state-13">
                  Indiana
                </option>
                <option value="Iowa" id="birth-state-14">
                  Iowa
                </option>
                <option value="Kansas" id="birth-state-15">
                  Kansas
                </option>
                <option value="Kentucky" id="birth-state-16">
                  Kentucky
                </option>
                <option value="Louisiana" id="birth-state-17">
                  Louisiana
                </option>
                <option value="Maine" id="birth-state-18">
                  Maine
                </option>
                <option value="Maryland" id="birth-state-19">
                  Maryland
                </option>
                <option value="Massachusetts" id="birth-state-20">
                  Massachusetts
                </option>
                <option value="Michigan" id="birth-state-21">
                  Michigan
                </option>
                <option value="Minnesota" id="birth-state-22">
                  Minnesota
                </option>
                <option value="Mississippi" id="birth-state-23">
                  Mississippi
                </option>
                <option value="Missouri" id="birth-state-24">
                  Missouri
                </option>
                <option value="Montana" id="birth-state-25">
                  Montana
                </option>
                <option value="Nebraska" id="birth-state-26">
                  Nebraska
                </option>
                <option value="Nevada" id="birth-state-27">
                  Nevada
                </option>
                <option value="New-Hampshire" id="birth-state-28">
                  New Hampshire
                </option>
                <option value="New-Jersey" id="birth-state-29">
                  New Jersey
                </option>
                <option value="New-Mexico" id="birth-state-30">
                  New Mexico
                </option>
                <option value="New-York" id="birth-state-31">
                  New York
                </option>
                <option value="North-Carolina" id="birth-state-32">
                  North Carolina
                </option>
                <option value="North-Dakota" id="birth-state-33">
                  North Dakota
                </option>
                <option value="Ohio" id="birth-state-34">
                  Ohio
                </option>
                <option value="Oklahoma" id="birth-state-35">
                  Oklahoma
                </option>
                <option value="Oregon" id="birth-state-36">
                  Oregon
                </option>
                <option value="Pennsylvania" id="birth-state-37">
                  Pennsylvania
                </option>
                <option value="Rhode-Island" id="birth-state-38">
                  Rhode Island
                </option>
                <option value="South-Carolina" id="birth-state-39">
                  South Carolina
                </option>
                <option value="South-Dakota" id="birth-state-40">
                  South Dakota
                </option>
                <option value="Tennessee" id="birth-state-41">
                  Tennessee
                </option>
                <option value="Texas" id="birth-state-42">
                  Texas
                </option>
                <option value="Utah" id="birth-state-43">
                  Utah
                </option>
                <option value="Vermont" id="birth-state-44">
                  Vermont
                </option>
                <option value="Virginia" id="birth-state-45">
                  Virginia
                </option>
                <option value="Washington" id="birth-state-46">
                  Washington
                </option>
                <option value="West-Virginia" id="birth-state-47">
                  West Virginia
                </option>
                <option value="Wisconsin" id="birth-state-48">
                  Wisconsin
                </option>
                <option value="Wyoming" id="birth-state-49">
                  Wyoming
                </option>
                <option value="Washington-DC" id="birth-state-50">
                  Washington, D.C.
                </option>
                <option value="Other" id="birth-state-51">
                  Other/Foreign Country (please specify)
                </option>
              </select>
            </div>
            {errors["birth-state"] && (
              <div className="error-msg">{errors["birth-state"].message}</div>
            )}
            <div className=" form-group field-birth-country"></div>
            <div className="block">
              <label for="birth-country" className="text-gray-700">
                If other or foreign country, please specify:{" "}
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("birth-country")}
                access="false"
                id="birth-country"
              />
            </div>
            {errors["birth-country"] && (
              <div className="error-msg">{errors["birth-country"].message}</div>
            )}
            <div className=" form-group field-date-of-birth"></div>
            <div className="block">
              <label for="date-of-birth" className="text-gray-700">
                Date of birth:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                placeholder="01/01/1921"
                className="form-input decedent-form"
                {...register("date-of-birth")}
                access="false"
                id="date-of-birth"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["date-of-birth"] && (
              <div className="error-msg">{errors["date-of-birth"].message}</div>
            )}
            <div className=" form-group field-date-of-death"></div>
            <div className="block">
              <label for="date-of-death" className="text-gray-700">
                Date of death:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                placeholder="01/01/2020"
                className="form-input decedent-form"
                {...register("date-of-death")}
                access="false"
                id="date-of-death"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["date-of-death"] && (
              <div className="error-msg">{errors["date-of-death"].message}</div>
            )}
            <div className=" form-group field-death-location"></div>
            <div className="block">
              <label for="death-location" className="text-gray-700">
                Location of death:
                <span className="tooltip">
                  <span className="tooltip-text">(for NC record keeping)</span>?
                </span>
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
                <br />
              </label>
              <select
                className="form-select decedent-form"
                {...register("death-location")}
                id="death-location"
                aria-required="true"
              >
                <option value={undefined} disabled selected="true">
                  Please select:
                </option>
                <option value="Decedent's home" id="death-location-0">
                  Decedent's home
                </option>
                <option value="Hospice facility" id="death-location-1">
                  Hospice facility
                </option>
                <option
                  value="Nursing home/long-term care facility"
                  id="death-location-2"
                >
                  Nursing home/long-term care facility
                </option>
                <option value="Hospital (inpatient)" id="death-location-3">
                  Hospital (inpatient)
                </option>
                <option value="Hospital (ER/outpatient)" id="death-location-4">
                  Hospital (ER/outpatient)
                </option>
                <option
                  value="Hospital (dead on arrival)"
                  id="death-location-5"
                >
                  Hospital (dead on arrival)
                </option>
                <option value="Other" id="death-location-6">
                  Other (please specify)
                </option>
              </select>
            </div>
            {errors["death-location"] && (
              <div className="error-msg">
                {errors["death-location"].message}
              </div>
            )}
            <div className=" form-group field-location-of-death-other"></div>
            <div className="block">
              <label for="location-of-death-other" className="text-gray-700">
                If other, please specify:
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-textarea decedent-form"
                {...register("location-of-death-other")}
                access="false"
                id="location-of-death-other"
              />
            </div>
            {errors["location-of-death-other"] && (
              <div className="error-msg">
                {errors["location-of-death-other"].message}
              </div>
            )}
            <div className=" form-group field-death-location-address">
              <label for="death-location-address" className="">
                <div className="block">
                  Address OR Facility Name:
                  <span className="tooltip">
                    <span className="tooltip-text">
                      (if not institution, give street and number)
                    </span>
                    ?
                  </span>
                </div>
              </label>
              <textarea
                type="textarea"
                className="form-textarea decedent-form"
                {...register("death-location-address")}
                access="false"
                id="death-location-address"
              ></textarea>
            </div>
            {errors["death-location-address"] && (
              <div className="error-msg">
                {errors["death-location-address"].message}
              </div>
            )}
            <div className=" form-group field-death-city"></div>
            <div className="block">
              <label for="death-city" className="text-gray-700">
                City or town:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("death-city")}
                access="false"
                id="death-city"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["death-city"] && (
              <div className="error-msg">{errors["death-city"].message}</div>
            )}
            <div className=" form-group field-death-county"></div>
            <div className="block">
              <label for="death-county" className="text-gray-700">
                County of death:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("death-county")}
                access="false"
                id="death-county"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["death-county"] && (
              <div className="error-msg">{errors["death-county"].message}</div>
            )}
            <div className=" form-group field-marital-status"></div>
            <div className="block">
              <label for="marital-status" className="text-gray-700">
                Marital Status
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
                <br />
              </label>
              <select
                className="form-select decedent-form"
                {...register("marital-status")}
                id="marital-status"
                aria-required="true"
              >
                <option value={undefined} disabled selected="true">
                  Please select:
                </option>
                <option value="Married" id="marital-status-0">
                  Married
                </option>
                <option value="Separated" id="marital-status-1">
                  Separated
                </option>
                <option value="Divorced" id="marital-status-2">
                  Divorced
                </option>
                <option value="Never married" id="marital-status-3">
                  Never married
                </option>
                <option value="Widowed" id="marital-status-4">
                  Widowed
                </option>
                <option value="Unknown" id="marital-status-5">
                  Unknown
                </option>
              </select>
            </div>
            {errors["marital-status"] && (
              <div className="error-msg">
                {errors["marital-status"].message}
              </div>
            )}
            <div className=" form-group field-surviving-spouse"></div>
            <div className="block">
              <label for="surviving-spouse" className="text-gray-700">
                Surviving spouse's name (if wife, use maiden name):
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("surviving-spouse")}
                access="false"
                id="surviving-spouse"
              />
            </div>
            {errors["surviving-spouse"] && (
              <div className="error-msg">
                {errors["surviving-spouse"].message}
              </div>
            )}
            <div className=" form-group field-business-occupation"></div>
            <div className="block">
              <label for="business-occupation" className="text-gray-700">
                Decedent's usual occupation (do not use retired or disabled):
                <span className="tooltip">
                  <span className="tooltip-text">
                    If retired or disabled, please list last occupation before
                    retirement or disability.
                  </span>
                  ?
                </span>
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("business-occupation")}
                access="false"
                id="business-occupation"
                title="(do not use retired)"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["business-occupation"] && (
              <div className="error-msg">
                {errors["business-occupation"].message}
              </div>
            )}
            <div className=" form-group field-business-industry"></div>
            <div className="block">
              <label for="business-industry" className="text-gray-700">
                Kind of business/industry:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("business-industry")}
                access="false"
                id="business-industry"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["business-industry"] && (
              <div className="error-msg">
                {errors["business-industry"].message}
              </div>
            )}
            <div className=" form-group field-ssn"></div>
            <div className="block">
              <label for="ssn" className="text-gray-700">
                Social Security Number
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                placeholder="000-00-0000"
                className="form-input decedent-form"
                {...register("ssn")}
                access="false"
                id="ssn"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["ssn"] && (
              <div className="error-msg">{errors["ssn"].message}</div>
            )}
            <br />
            <h3>Decedent's residence:</h3>
            <div className=" form-group field-residence-state"></div>
            <div className="block">
              <label for="residence-state" className="text-gray-700">
                Residence State:
                <br />
              </label>
              <select
                className="form-select decedent-form"
                {...register("residence-state")}
                access="false"
                id="residence-state"
              >
                <option value={undefined} disabled selected="true">
                  Please select:
                </option>
                <option value="Alabama" id="residence-state-0">
                  Alabama
                </option>
                <option value="Alaska" id="residence-state-1">
                  Alaska
                </option>
                <option value="Arizona" id="residence-state-2">
                  Arizona
                </option>
                <option value="Arkansas" id="residence-state-3">
                  Arkansas
                </option>
                <option value="California" id="residence-state-4">
                  California
                </option>
                <option value="Colorado" id="residence-state-5">
                  Colorado
                </option>
                <option value="Connecticut" id="residence-state-6">
                  Connecticut
                </option>
                <option value="Delaware" id="residence-state-7">
                  Delaware
                </option>
                <option value="Florida" id="residence-state-8">
                  Florida
                </option>
                <option value="Georgia" id="residence-state-9">
                  Georgia
                </option>
                <option value="Hawaii" id="residence-state-10">
                  Hawaii
                </option>
                <option value="Idaho" id="residence-state-11">
                  Idaho
                </option>
                <option value="Illinois" id="residence-state-12">
                  Illinois
                </option>
                <option value="Indiana" id="residence-state-13">
                  Indiana
                </option>
                <option value="Iowa" id="residence-state-14">
                  Iowa
                </option>
                <option value="Kansas" id="residence-state-15">
                  Kansas
                </option>
                <option value="Kentucky" id="residence-state-16">
                  Kentucky
                </option>
                <option value="Louisiana" id="residence-state-17">
                  Louisiana
                </option>
                <option value="Maine" id="residence-state-18">
                  Maine
                </option>
                <option value="Maryland" id="residence-state-19">
                  Maryland
                </option>
                <option value="Massachusetts" id="residence-state-20">
                  Massachusetts
                </option>
                <option value="Michigan" id="residence-state-21">
                  Michigan
                </option>
                <option value="Minnesota" id="residence-state-22">
                  Minnesota
                </option>
                <option value="Mississippi" id="residence-state-23">
                  Mississippi
                </option>
                <option value="Missouri" id="residence-state-24">
                  Missouri
                </option>
                <option value="Montana" id="residence-state-25">
                  Montana
                </option>
                <option value="Nebraska" id="residence-state-26">
                  Nebraska
                </option>
                <option value="Nevada" id="residence-state-27">
                  Nevada
                </option>
                <option value="New-Hampshire" id="residence-state-28">
                  New Hampshire
                </option>
                <option value="New-Jersey" id="residence-state-29">
                  New Jersey
                </option>
                <option value="New-Mexico" id="residence-state-30">
                  New Mexico
                </option>
                <option value="New-York" id="residence-state-31">
                  New York
                </option>
                <option value="North-Carolina" id="residence-state-32">
                  North Carolina
                </option>
                <option value="North-Dakota" id="residence-state-33">
                  North Dakota
                </option>
                <option value="Ohio" id="residence-state-34">
                  Ohio
                </option>
                <option value="Oklahoma" id="residence-state-35">
                  Oklahoma
                </option>
                <option value="Oregon" id="residence-state-36">
                  Oregon
                </option>
                <option value="Pennsylvania" id="residence-state-37">
                  Pennsylvania
                </option>
                <option value="Rhode-Island" id="residence-state-38">
                  Rhode Island
                </option>
                <option value="South-Carolina" id="residence-state-39">
                  South Carolina
                </option>
                <option value="South-Dakota" id="residence-state-40">
                  South Dakota
                </option>
                <option value="Tennessee" id="residence-state-41">
                  Tennessee
                </option>
                <option value="Texas" id="residence-state-42">
                  Texas
                </option>
                <option value="Utah" id="residence-state-43">
                  Utah
                </option>
                <option value="Vermont" id="residence-state-44">
                  Vermont
                </option>
                <option value="Virginia" id="residence-state-45">
                  Virginia
                </option>
                <option value="Washington" id="residence-state-46">
                  Washington
                </option>
                <option value="West-Virginia" id="residence-state-47">
                  West Virginia
                </option>
                <option value="Wisconsin" id="residence-state-48">
                  Wisconsin
                </option>
                <option value="Wyoming" id="residence-state-49">
                  Wyoming
                </option>
                <option value="Washington-DC" id="residence-state-50">
                  Washington, D.C.
                </option>
                <option value="Other" id="residence-state-51">
                  Other/Foreign Country (please specify)
                </option>
              </select>
            </div>
            {errors["residence-state"] && (
              <div className="error-msg">
                {errors["residence-state"].message}
              </div>
            )}
            <div className=" form-group field-residence-country"></div>
            <div className="block">
              <label for="residence-country" className="text-gray-700">
                If foreign country, please specify:{" "}
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("residence-country")}
                access="false"
                id="residence-country"
              />
            </div>
            {errors["residence-country"] && (
              <div className="error-msg">
                {errors["residence-country"].message}
              </div>
            )}
            <div className=" form-group field-residence-county"></div>
            <div className="block">
              <label for="residence-county" className="text-gray-700">
                County:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("residence-county")}
                access="false"
                id="residence-county"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["residence-county"] && (
              <div className="error-msg">
                {errors["residence-county"].message}
              </div>
            )}
            <div className=" form-group field-residence-address"></div>
            <div className="block">
              <label for="residence-address" className="text-gray-700">
                Street and number:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("residence-address")}
                access="false"
                id="residence-address"
              ></input>
            </div>
            {errors["residence-address"] && (
              <div className="error-msg">
                {errors["residence-address"].message}
              </div>
            )}
            <div className=" form-group field-residence-city"></div>
            <div className="block">
              <label for="residence-city" className="text-gray-700">
                City:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("residence-city")}
                access="false"
                id="residence-city"
              ></input>
            </div>
            {errors["residence-city"] && (
              <div className="error-msg">
                {errors["residence-city"].message}
              </div>
            )}
            <div className=" form-group field-residence-zip"></div>
            <div className="block">
              <label for="residence-zip" className="text-gray-700">
                Zip Code:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("residence-zip")}
                access="false"
                id="residence-zip"
              ></input>
            </div>
            {errors["residence-zip"] && (
              <div className="error-msg">{errors["residence-zip"].message}</div>
            )}
            <div className=" form-group field-city-limits"></div>
            <div className="block">
              <label for="city-limits" className="text-gray-700">
                Is the residence within city limits?
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
                <br />
              </label>
              <select
                className="form-select city-limits"
                {...register("city-limits")}
                access="false"
                id="city-limits"
                required="required"
                aria-required="true"
              >
                <option value={undefined} disabled selected="true">
                  Please select:{" "}
                </option>
                <option value="yes" id="city-limits-1">
                  Yes
                </option>
                <option value="no" id="city-limits-0">
                  No
                </option>
              </select>
            </div>
            {errors["city-limits"] && (
              <div className="error-msg">{errors["city-limits"].message}</div>
            )}
            <br />
            <h3>Background:</h3>
            <div className=" form-group field-armed-forces"></div>
            <div className="block">
              <label for="armed-forces" className="text-gray-700">
                Was the decedent ever in the U.S. Armed Forces?
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
                <br />
              </label>
              <select
                className="form-select armed-forces"
                {...register("armed-forces")}
                access="false"
                id="armed-forces"
                required="required"
                aria-required="true"
              >
                <option value={undefined} disabled selected="true">
                  Please select:
                </option>
                <option value="yes" id="armed-forces-1">
                  Yes
                </option>
                <option value="no" id="armed-forces-0">
                  No
                </option>
              </select>
            </div>
            {errors["armed-forces"] && (
              <div className="error-msg">{errors["armed-forces"].message}</div>
            )}
            <div className=" form-group field-armed-forces-discharge-papers"></div>
            <div className="block">
              <label for="armed-forces-discharge-papers" className="">
                If yes, is a copy of the decedent's military discharge papers
                available?
                <br />
              </label>
              <select
                className="form-select armed-forces-discharge-papers"
                {...register("armed-forces-discharge-papers")}
                access="false"
                id="armed-forces-discharge-papers"
              >
                <option value={undefined} disabled selected="true">
                  If applicable, please select:
                </option>
                <option value="yes" id="armed-forces-discharge-papers-1">
                  Yes
                </option>
                <option value="no" id="armed-forces-discharge-papers-0">
                  No
                </option>
              </select>
            </div>
            {errors["armed-forces-discharge-papers"] && (
              <div className="error-msg">
                {errors["armed-forces-discharge-papers"].message}
              </div>
            )}
            <div className=" form-group field-education"></div>
            <div className="block">
              <label for="education" className="text-gray-700">
                Education completed:
                <span className="tooltip">
                  <span className="tooltip-text">
                    (the highest degree or level of school completed at time of
                    death)
                  </span>
                  ?
                </span>
                <br />
              </label>
              <select
                className="form-select decedent-form"
                {...register("education")}
                id="education"
              >
                <option value={undefined} disabled selected="true">
                  Please select:
                </option>
                <option value="8th grade" id="education-0">
                  8th grade or less
                </option>
                <option value="12th grade" id="education-1">
                  9th-12th grade; no diploma
                </option>
                <option value="High school" id="education-2">
                  High school graduate or GED completed
                </option>
                <option value="Some college" id="education-3">
                  Some college credit, but no degree
                </option>
                <option value="Associate degree" id="education-4">
                  Associate degree
                </option>
                <option value="Bachelor's degree" id="education-5">
                  Bachelor's degree
                </option>
                <option value="Master's degree" id="education-6">
                  Master's degree
                </option>
                <option value="Doctorate" id="education-7">
                  Doctorate or Professional degree
                </option>
              </select>
            </div>
            {errors["education"] && (
              <div className="error-msg">{errors["education"].message}</div>
            )}
            <div className=" form-group field-race"></div>
            <div className="block">
              <label for="race" className="text-gray-700">
                Decedent's Race:
                <span className="tooltip">
                  <span className="tooltip-text">
                    (if multiple, please specify below)
                  </span>
                  ?
                </span>
                <br />
              </label>
              <select
                className="form-select decedent-form"
                {...register("race")}
                id="race"
              >
                <option value={undefined} disabled selected="true">
                  Please select:
                </option>
                <option value="Black or African American" id="education-0">
                  Black or African American
                </option>
                <option value="White" id="education-1">
                  White
                </option>
                <option value="Hispanic" id="education-2">
                  Hispanic
                </option>
                <option value="Other" id="education-3">
                  Other (please specify)
                </option>
              </select>
            </div>
            {errors["race"] && (
              <div className="error-msg">{errors["race"].message}</div>
            )}
            <div className=" form-group field-race-specific"></div>
            <div className="block">
              <label for="race-specific" className="text-gray-700">
                Please specify if necessary:
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("race-specific")}
                access="false"
                id="race-specific"
              />
            </div>
            {errors["race-specific"] && (
              <div className="error-msg">{errors["race-specific"].message}</div>
            )}
            <div className=" form-group field-father-name">
              <br />
              <h3>Decedent's father's full legal name:</h3>
            </div>
            <div className="block">
              <label for="father-name-first" className="text-gray-700">
                First:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("father-name-first")}
                access="false"
                id="father-name-first"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["father-name-first"] && (
              <div className="error-msg">
                {errors["father-name-first"].message}
              </div>
            )}
            <div className="block">
              <label for="father-name-middle" className="text-gray-700">
                Middle:
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("father-name-middle")}
                access="false"
                id="father-name-middle"
              />
            </div>
            {errors["father-name-middle"] && (
              <div className="error-msg">
                {errors["father-name-middle"].message}
              </div>
            )}
            <div className="block">
              <label for="father-name-last" className="text-gray-700">
                Last:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("father-name-last")}
                access="false"
                id="father-name-last"
                required="required"
                aria-required="true"
              />

              {errors["father-name-last"] && (
                <div className="error-msg">
                  {errors["father-name-last"].message}
                </div>
              )}
              <div className=" form-group field-mother-name">
                <br />
                <h3>Decedent's mother's full name:</h3>
              </div>
              <div className="block">
                <label for="mother-name-first" className="text-gray-700">
                  First:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input decedent-form"
                  {...register("mother-name-first")}
                  access="false"
                  id="mother-name-first"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["mother-name-first"] && (
                <div className="error-msg">
                  {errors["mother-name-first"].message}
                </div>
              )}

              <div className="block">
                <label for="mother-name-middle" className="text-gray-700">
                  Middle:
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input decedent-form"
                  {...register("mother-name-middle")}
                  access="false"
                  id="mother-name-middle"
                />
              </div>
              {errors["mother-name-middle"] && (
                <div className="error-msg">
                  {errors["mother-name-middle"].message}
                </div>
              )}

              <div className="block">
                <label for="mother-name-last" className="text-gray-700">
                  Last (maiden name):
                  <span className="tooltip">
                    <span className="tooltip-text">
                      (if known, put maiden name, if not, put married name)
                    </span>
                    ?
                  </span>
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input decedent-form"
                  {...register("mother-name-last")}
                  access="false"
                  id="mother-name-last"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["mother-name-last"] && (
                <div className="error-msg">
                  {errors["mother-name-last"].message}
                </div>
              )}
            </div>
            <h3>Informant: </h3>{" "}
            <div className=" form-group field-informant-name"></div>
            <div className="block">
              <label for="informant-name" className="text-gray-700">
                Name of person making arrangements (the "informant"):
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("informant-name")}
                access="false"
                id="informant-name"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["informant-name"] && (
              <div className="error-msg">
                {errors["informant-name"].message}
              </div>
            )}
            <div className=" form-group field-informant-relationship"></div>
            <div className="block">
              <label for="informant-relationship" className="text-gray-700">
                Informant's relationship to decedent:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("informant-relationship")}
                access="false"
                id="informant-relationship"
                required="required"
                aria-required="true"
              />
            </div>
            {errors["informant-relationship"] && (
              <div className="error-msg">
                {errors["informant-relationship"].message}
              </div>
            )}
            <h4>Informant's address:</h4>
            <div className=" form-group field-informant-address"></div>
            <div className="block">
              <label for="informant-address" className="text-gray-700">
                Street and number:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("informant-address")}
                access="false"
                id="informant-address"
              ></input>
            </div>
            {errors["informant-address"] && (
              <div className="error-msg">
                {errors["informant-address"].message}
              </div>
            )}
            <div className=" form-group field-informant-city"></div>
            <div className="block">
              <label for="informant-city" className="text-gray-700">
                City:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
            </div>
            <div className="block">
              <input
                type="text"
                className="form-input decedent-form"
                {...register("informant-city")}
                access="false"
                id="informant-city"
              ></input>
            </div>
            {errors["informant-city"] && (
              <div className="error-msg">
                {errors["informant-city"].message}
              </div>
            )}
            <div className=" form-group field-informant-state"></div>
            <div className="block">
              <label for="informant-state" className="text-gray-700">
                State:
                <br />{" "}
              </label>
              <select
                className="form-select decedent-form"
                {...register("informant-state")}
                access="false"
                id="informant-state"
              >
                <option value={undefined} disabled selected="true">
                  Please select:
                </option>
                <option value="Alabama" id="informant-state-0">
                  Alabama
                </option>
                <option value="Alaska" id="informant-state-1">
                  Alaska
                </option>
                <option value="Arizona" id="informant-state-2">
                  Arizona
                </option>
                <option value="Arkansas" id="informant-state-3">
                  Arkansas
                </option>
                <option value="California" id="informant-state-4">
                  California
                </option>
                <option value="Colorado" id="informant-state-5">
                  Colorado
                </option>
                <option value="Connecticut" id="informant-state-6">
                  Connecticut
                </option>
                <option value="Delaware" id="informant-state-7">
                  Delaware
                </option>
                <option value="Florida" id="informant-state-8">
                  Florida
                </option>
                <option value="Georgia" id="informant-state-9">
                  Georgia
                </option>
                <option value="Hawaii" id="informant-state-10">
                  Hawaii
                </option>
                <option value="Idaho" id="informant-state-11">
                  Idaho
                </option>
                <option value="Illinois" id="informant-state-12">
                  Illinois
                </option>
                <option value="Indiana" id="informant-state-13">
                  Indiana
                </option>
                <option value="Iowa" id="informant-state-14">
                  Iowa
                </option>
                <option value="Kansas" id="informant-state-15">
                  Kansas
                </option>
                <option value="Kentucky" id="informant-state-16">
                  Kentucky
                </option>
                <option value="Louisiana" id="informant-state-17">
                  Louisiana
                </option>
                <option value="Maine" id="informant-state-18">
                  Maine
                </option>
                <option value="Maryland" id="informant-state-19">
                  Maryland
                </option>
                <option value="Massachusetts" id="informant-state-20">
                  Massachusetts
                </option>
                <option value="Michigan" id="informant-state-21">
                  Michigan
                </option>
                <option value="Minnesota" id="informant-state-22">
                  Minnesota
                </option>
                <option value="Mississippi" id="informant-state-23">
                  Mississippi
                </option>
                <option value="Missouri" id="informant-state-24">
                  Missouri
                </option>
                <option value="Montana" id="informant-state-25">
                  Montana
                </option>
                <option value="Nebraska" id="informant-state-26">
                  Nebraska
                </option>
                <option value="Nevada" id="informant-state-27">
                  Nevada
                </option>
                <option value="New-Hampshire" id="informant-state-28">
                  New Hampshire
                </option>
                <option value="New-Jersey" id="informant-state-29">
                  New Jersey
                </option>
                <option value="New-Mexico" id="informant-state-30">
                  New Mexico
                </option>
                <option value="New-York" id="informant-state-31">
                  New York
                </option>
                <option value="North-Carolina" id="informant-state-32">
                  North Carolina
                </option>
                <option value="North-Dakota" id="informant-state-33">
                  North Dakota
                </option>
                <option value="Ohio" id="informant-state-34">
                  Ohio
                </option>
                <option value="Oklahoma" id="informant-state-35">
                  Oklahoma
                </option>
                <option value="Oregon" id="informant-state-36">
                  Oregon
                </option>
                <option value="Pennsylvania" id="informant-state-37">
                  Pennsylvania
                </option>
                <option value="Rhode-Island" id="informant-state-38">
                  Rhode Island
                </option>
                <option value="South-Carolina" id="informant-state-39">
                  South Carolina
                </option>
                <option value="South-Dakota" id="informant-state-40">
                  South Dakota
                </option>
                <option value="Tennessee" id="informant-state-41">
                  Tennessee
                </option>
                <option value="Texas" id="informant-state-42">
                  Texas
                </option>
                <option value="Utah" id="informant-state-43">
                  Utah
                </option>
                <option value="Vermont" id="informant-state-44">
                  Vermont
                </option>
                <option value="Virginia" id="informant-state-45">
                  Virginia
                </option>
                <option value="Washington" id="informant-state-46">
                  Washington
                </option>
                <option value="West-Virginia" id="informant-state-47">
                  West Virginia
                </option>
                <option value="Wisconsin" id="informant-state-48">
                  Wisconsin
                </option>
                <option value="Wyoming" id="informant-state-49">
                  Wyoming
                </option>
                <option value="Washington-DC" id="informant-state-50">
                  Washington, D.C.
                </option>
              </select>
              {errors["informant-state"] && (
                <div className="error-msg">
                  {errors["informant-state"].message}
                </div>
              )}
              <div className=" form-group field-informant-zip"></div>
              <div className="block">
                <label for="informant-zip" className="text-gray-700">
                  Zip Code:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input decedent-form"
                  {...register("informant-zip")}
                  access="false"
                  id="informant-zip"
                ></input>
              </div>{" "}
              {errors["informant-zip"] && (
                <div className="error-msg">
                  {errors["informant-zip"].message}
                </div>
              )}
              <div className=" form-group field-informant-telephone"></div>
              <div className="block">
                <label for="informant-telephone" className="text-gray-700">
                  Informant's telephone number:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  placeholder="999-999-9999"
                  className="form-input decedent-form"
                  {...register("informant-telephone")}
                  access="false"
                  id="informant-telephone"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["informant-telephone"] && (
                <div className="error-msg">
                  {errors["informant-telephone"].message}
                </div>
              )}
              <div className=" form-group field-informant-email"></div>
              <div className="block">
                <label for="informant-email" className="text-gray-700">
                  Informant's email address:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input decedent-form"
                  {...register("informant-email")}
                  access="false"
                  id="informant-email"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["informant-email"] && (
                <div className="error-msg">
                  {errors["informant-email"].message}
                </div>
              )}
              <div className=" form-group field-city-limits"></div>
              <div className="block">
                <label for="city-limits" className="text-gray-700">
                  Was the decedent under hospice care at the time of death?
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                  <br />
                </label>
                <select
                  className="form-select hospice"
                  {...register("hospice")}
                  access="false"
                  id="hospice"
                  required="required"
                  aria-required="true"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="yes" id="hospice-1">
                    Yes
                  </option>
                  <option value="no" id="hospice-0">
                    No
                  </option>
                </select>
              </div>
              {errors["hospice"] && (
                <div className="error-msg">{errors["hospice"].message}</div>
              )}
              <div className=" form-group field-hospice-name"></div>
              <div className="block">
                <label for="hospice-name" className="text-gray-700">
                  Name of hospice provider:
                  <span className="tooltip">
                    <span className="tooltip-text">(if yes)</span>?
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input decedent-form"
                  {...register("hospice-name")}
                  access="false"
                  id="hospice-name"
                  title="(if yes above)"
                />
              </div>
              {errors["hospice-name"] && (
                <div className="error-msg">
                  {errors["hospice-name"].message}
                </div>
              )}
              <div className=" form-group field-pacemaker"></div>
              <div className="block">
                <label for="pacemaker" className="text-gray-700">
                  Did the decedent have a pacemaker?
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                  <br />
                </label>
                <select
                  className="form-select pacemaker"
                  {...register("pacemaker")}
                  access="false"
                  id="pacemaker"
                  required="required"
                  aria-required="true"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="yes" id="pacemaker-1">
                    Yes
                  </option>
                  <option value="no" id="pacemaker-0">
                    No
                  </option>
                </select>
              </div>
              {errors["pacemaker"] && (
                <div className="error-msg">{errors["pacemaker"].message}</div>
              )}
              <div>
                Three (3) Certified Death Certificates are already included.
                Please note any additional certificates needed here.
              </div>
              <div className=" form-group field-death-certificates-number"></div>
              <div className="block">
                <label for="death-certificates-number" className="">
                  Death certificates requested:
                  <span className="tooltip">
                    <span className="tooltip-text">
                      (each additional certificate after the first three costs
                      $15)
                    </span>
                    ?
                  </span>
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                  <br />
                </label>
              </div>
              <div className="block">
                <small>
                  <b>
                    <span className="mt-2">3 certificates included +</span>
                  </b>
                </small>
                <br />
                <span>
                  <input
                    type="number"
                    className="form-input decedent-form"
                    {...register("death-certificates-number")}
                    access="false"
                    // value="0"
                    min="0"
                    id="death-certificates-number"
                    title="(each additional certificate costs $15)"
                    required="required"
                    aria-required="true"
                  />{" "}
                  additional certificates
                </span>
                <br />
                <span className="mt-2">
                  = <b>{3 + +deathCertificatesNumber} total</b> death
                  certificates.
                </span>
              </div>
              {errors["pacemaker"] && (
                <div className="error-msg">{errors["pacemaker"].message}</div>
              )}
            </div>
          </div>
          <div>
            <br />
            <p>
              <strong>
                IMPORTANT: Please do not rely on nursing staff, social workers,
                first responders and/or transportation staff to relay important
                information or messages regarding your loved one. For example,
                questions, comments or concerns regarding your loved one’s
                personal effects (jewelry, clothing, etc) that you wish to be
                removed and returned, should be communicated directly to a City
                of Oaks Cremation, LLC Funeral Director.{" "}
              </strong>
            </p>
            <br />
            <div className="form-group form-check field-obit-consent"></div>
            <div className="block mt-2">
              <input
                type="checkbox"
                className="form-check-input decedent-form mr-2"
                {...register("obit-consent")}
                access="false"
                id="obit-consent"
                title="Yes"
                checked
              />
              <label for="obit-consent" className="text-gray-700">
                Yes, please publish a shareable online obituary for free.
                <span className="tooltip">
                  <span className="tooltip-text">
                    (we'll host it on our site and you'll have time to provide a
                    brief obituary and a photo later)
                  </span>
                  ?
                </span>
              </label>
            </div>
            <br />
            <p>
              We are committed to making this process as easy as possible for
              you. As a courtesy to the community, City of Oaks Cremation, LLC
              automatically places a public notice of death in our local
              newspaper publication. Please do not hesitate to call us with any
              questions and/or concerns. We are available to you 24 hours a day
              / 7 days a week.
            </p>
          </div>
          <div className="flex items-center align-center justify-center py-4">
            <input
              className="py-2 px-4 bg-green-700 hover:bg-green-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1 cursor-pointer"
              type="submit"
              value="Submit"
              disabled={loading}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default AtNeedForm;
