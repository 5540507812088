const fieldsArr = [
  'submission-type',
  'time-submitted',
  'legal-name-first',
  'legal-name-middle',
  'legal-name-last',
  'maiden-name',
  'sex',
  'age',
  'county',
  'birth-state',
  'birth-country',
  'date-of-birth',
  'date-of-death',
  'death-location',
  'location-of-death-other',
  'death-location-address',
  'death-city',
  'death-county',
  'marital-status',
  'surviving-spouse',
  'business-occupation',
  'business-industry',
  'ssn',
  'residence-state',
  'residence-country',
  'residence-county',
  'residence-address',
  'residence-city',
  'residence-zip',
  'city-limits',
  'armed-forces',
  'armed-forces-discharge-papers',
  'education',
  'race',
  'race-specific',
  'father-name-first',
  'father-name-middle',
  'father-name-last',
  'mother-name-first',
  'mother-name-middle',
  'mother-name-last',
  'informant-name',
  'informant-relationship',
  'informant-address',
  'informant-city',
  'informant-state',
  'informant-zip',
  'informant-telephone',
  'informant-email',
  'hospice',
  'hospice-name',
  'pacemaker',
  'death-certificates-number',
  'obit-consent',
];

export default fieldsArr;
