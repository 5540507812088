import dayjs from 'dayjs';
import * as Yup from 'yup';

let now = dayjs;
const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
const zipRegex = RegExp(/(^\d{5}(?:[\s]?[-\s][\s]?\d{4})?$)/);
const ssnRegex = RegExp(/^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/);

const schema = Yup.object().shape({
  'legal-name-first': Yup.string().required('Required'),
  'legal-name-middle': Yup.string(),
  'legal-name-last': Yup.string().required('Required'),
  'maiden-name': Yup.string().when(['sex', 'marital-status'], {
    is: (sex, status) =>
      sex === 'female' &&
      ['Married', 'Separated', 'Divorced', 'Widowed'].includes(status),
    then: Yup.string().required(
      'Required. If unknown or not applicable, put Unknown or N/A'
    ),
    otherwise: Yup.string().notRequired(),
  }),
  sex: Yup.string()
    .oneOf(['male', 'female'], 'Please select.')
    .required('Please select.'),
  age: Yup.number().typeError('Please enter a number.').required('Required'),

  county: Yup.string().when('birth-state', {
    is: 'Other',
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required(
      'Required if born in the US. If unknown, put Unknown.'
    ),
  }),
  'birth-state': Yup.string().required('Please select.'),
  'birth-country': Yup.string().when('birth-state', {
    is: 'Other',
    then: Yup.string().required('Please specify.'),
  }),
  'date-of-birth': Yup.date()
    .typeError('Please enter a date.')
    .max(now(), "Please enter a date earlier than today's date.")
    .required('Required'),
  // .transform((date) => now(date).format("MM/DD/YYYY")),
  'date-of-death': Yup.date()
    .typeError('Please enter a date.')
    .max(now(), "Please enter a date earlier than today's date.")
    .required('Required'),
  // .transform((date) => now(date).format("MM/DD/YYYY")),
  'death-location': Yup.string().oneOf(
    [
      "Decedent's home",
      'Hospice facility',
      'Nursing home/long-term care facility',
      'Hospital (inpatient)',
      'Hospital (ER/outpatient)',
      'Hospital (dead on arrival)',
      'Other',
    ],
    'Please select one.'
  ),
  'location-of-death-other': Yup.string().when('death-location', {
    is: 'Other',
    then: Yup.string().required('For other, please specify.'),
  }),
  'death-location-address': Yup.string().when('death-location', {
    is: "Individual's home",
    then: Yup.string().required(
      "Please specify the address of the individual's home."
    ),
    otherwise: Yup.string().required(
      'Please specify the name of the facility or else the address of the occurrence.'
    ),
  }),
  // .when("death-location", {
  //   is:
  //     "Hospice facility" ||
  //     "Nursing home/long-term care facility" ||
  //     "Hospital (inpatient)" ||
  //     "Hospital (ER/outpatient)",
  //   then: Yup.string().required(
  //     "Please specify the name of the facility or it's address."
  //   ),
  // }),

  'death-city': Yup.string().required('Required'),
  'death-county': Yup.string().required('Required'),
  'marital-status': Yup.string()
    .oneOf(
      [
        'Married',
        'Separated',
        'Divorced',
        'Never married',
        'Widowed',
        'Unknown',
      ],
      'Please choose one.'
    )
    .required('Please select.'),
  'surviving-spouse': Yup.string().when('marital-status', {
    is: 'Married',
    then: Yup.string().required('If applicable please specify.'),
  }),
  'business-occupation': Yup.string().required('Required'),
  'business-industry': Yup.string().required('Required'),
  ssn: Yup.string()
    .matches(ssnRegex, 'Please provide a valid SSN.')
    .required('Social Security Number is required.'),
  'residence-state': Yup.string().required('Required'),
  'residence-country': Yup.string().when('residence-state', {
    is: 'Other',
    then: Yup.string().required('Please specify.'),
  }),
  'residence-county': Yup.string().when('residence-state', {
    is: 'Other',
    then: Yup.string(),
    otherwise: Yup.string().required('Required'),
  }),
  'residence-address': Yup.string().required('Required'),
  'residence-city': Yup.string().required('Required'),
  'residence-zip': Yup.string().matches(
    zipRegex,
    'Please provide a valid zip code.'
  ),
  'city-limits': Yup.string()
    .oneOf(['yes', 'no'], 'Please select one.')
    .required('Please select.'),
  'armed-forces': Yup.string()
    .oneOf(['yes', 'no'], 'Please select one.')
    .required('Please select.'),
  'armed-forces-discharge-papers': Yup.string().when('armed-forces', {
    is: 'yes',
    then: Yup.string()
      .oneOf(['yes', 'no'], 'If applicable, please specify.')
      .required('If applicable, please specify.'),
  }),
  education: Yup.string().oneOf(
    [
      '8th grade',
      '12th grade',
      'High school',
      'Some college',
      'Associate degree',
      "Bachelor's degree",
      "Master's degree",
      'Doctorate',
    ],
    'Please select'
  ),
  race: Yup.string().oneOf(
    ['Black or African American', 'White', 'Hispanic', 'Other'],
    'Please select one.'
  ),
  'race-specific': Yup.string().when('race', {
    is: 'Other',
    then: Yup.string().required('If other, please specify'),
  }),
  'father-name-first': Yup.string().required('Required'),
  'father-name-middle': Yup.string(),
  'father-name-last': Yup.string().required('Required'),
  'mother-name-first': Yup.string().required('Required'),
  'mother-name-middle': Yup.string(),
  'mother-name-last': Yup.string().required('Required'),
  'informant-name': Yup.string().required(
    'Please provide the name of the person making these arrangements'
  ),
  'informant-relationship': Yup.string().required(
    "What is the informant's relationship to the individual?"
  ),
  'informant-address': Yup.string().required('Required'),
  'informant-city': Yup.string().required('Required'),
  'informant-state': Yup.string(),
  'informant-zip': Yup.string()
    .matches(zipRegex, 'Please enter a valid zip code')
    .required('Required'),
  'informant-telephone': Yup.string()
    .matches(phoneRegex, 'Please provide a valid phone number.')
    .required('A telephone number is required.'),
  'informant-email': Yup.string()
    .email('Please provide a valid email address.')
    .required('Email is required'),
  hospice: Yup.string(),
  'hospice-name': Yup.string().when('hospice', {
    is: 'yes',
    then: Yup.string().required('If yes, please list.'),
  }),
  pacemaker: Yup.string().required('Please select'),
  'death-certificates-number': Yup.number().min(0),
  // .required(
  //   'Please enter the number of additional death certificates you would like, or else enter zero.'
  // ),
});

export default schema;
